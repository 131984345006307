/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/react@15.6.1/dist/react.min.js
 * - /npm/react-dom@15.6.1/dist/react-dom.min.js
 * - /npm/redux@3.7.2/dist/redux.min.js
 * - /npm/prop-types@15.5.10/prop-types.min.js
 * - /npm/react-redux@5.0.5/dist/react-redux.min.js
 * - /npm/redux-thunk@2.2.0/dist/redux-thunk.min.js
 * - /npm/react-router@4.1.2/umd/react-router.min.js
 * - /npm/react-router-redux@5.0.0-alpha.6/umd/react-router-redux.min.js
 * - /npm/mobx@3.3.0/lib/mobx.umd.min.js
 * - /npm/mobx-react@4.3.2/index.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
